@import '../lib/beagle-sass/config/beagle-variables';

.rwc-LocDayReport-map {
  height: 500px;
}

.rwc-LocDayReport-legend {
  max-width: 15em;
  margin-bottom: 1em;
}

.rwc-LocDayReport-wrapper {
  @include media-breakpoint-up(dm) {
    position: sticky;
    top: $top-header-height + 2px;
  }
}