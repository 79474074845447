.rwc-ReportGroup-arrow {
  cursor: pointer;
}

.rwc-ReportGroup-arrow--expanded {
  .icon {
    transform: rotate(90deg);
  }
}

.rwc-ReportGroup-level-1 {
  font-size: 120%;
}

.rwc-ReportGroup-level-2 {
  margin-left: 1em;
}

.rwc-Survey-question {
  margin-bottom: 5px;
}
