// application shared styles

.text-crossed-out {
  text-decoration: line-through !important;
}

.rwc-Utils-highlight {
  animation-name: rwc-Utils-animateHighlight;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes rwc-Utils-animateHighlight
{
  50%     {background: yellow;}
}

.rwc-Utils-setTitle {
  display: none;
}

.rwc-Utils-hidden {
  display: none;
}

.rwc-Utils-indent-1 {
  font-weight: bold !important;
}
.rwc-Utils-indent-2 {
  padding-left: 2em !important;
}
.rwc-Utils-indent-3 {
  padding-left: 4em !important;
}
.rwc-Utils-indent-4 {
  padding-left: 6em !important;
}
.rwc-Utils-indent-5 {
  padding-left: 8em !important;
}

.rwc-Utils-dataChanged {
  background-color: #ffa0a0;
}

.rwc-Utils-tableRotate {
  th {
    vertical-align: bottom;
  }
  .rwc-Utils-thRotate {
    height: 170px;
    white-space: nowrap;
    padding: 4px 4px 8px 4px;
  }
  .rwc-Utils-thRotate > div {
    width: 20px;
    transform: rotate(-90deg);
    transform-origin: center center 0;
  }
}

.rwc-Utils-treeTable {
  width: auto;
  height: 200px;
  overflow: auto;
  border: 1px solid #ccc;
  background-color: #FFF;
  .node_level_3.added {
    font-style: italic;
    color: #777;
  }
}

.rwc-Utils-perPage {
  padding: 2px 2px 2px 2px;
}

.rwc-Utils-resetFont {
  font-size: 13px !important;
  font-weight: 400;
}

.rwc-RowGap-1 {
  row-gap: 10px;
}

.rwc-RowGap-2 {
  row-gap: 20px;
}

.rwc-Utils-darkerBackground {
  background: rgba(0, 0, 0, 0.4);
  padding: 2px;
}