.rwc-Merger-idLabel {
  display: inline-block;
  width: 4em;
}

.rwc-Merger-cmpShorten1 {
  max-width: 10em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rwc-Merger-cmpShorten2 {
  max-width: 20em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rwc-Merger-cmpShorten3 {
  max-width: 30em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rwc-Merger-cmpShorten4 {
  max-width: 40em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rwc-Merger-map {
  width: 100%;
  height: 600px;
}

.rwc-Merger-prevDiff {
  td {
    border-top: 1px solid #999;
  }
}

.rwc-Merger-match-0-zipcode-exact {
  td {
    color: green;
    font-weight: bold;
  }
}

.rwc-Merger-match-1-number-exact {
  td {
    color: green;
  }
}

.rwc-Merger-match-2-street-exact {
  td {
    color: green;
  }
}

.rwc-Merger-match-3-city-exact {
  td {
    color: #4e4e4e;
  }
}

.rwc-Merger-match-4-city-similar {
  td {
    color: #797979;
  }
}