// Overwrite templates variables here
$logo-bg:                               "../../images/refwell-logo.svg";
$logo-bg-2x:                            "../../images/refwell-logo.svg";

$logo-width:                            110px;
$logo-height:                           36px;
$logo-padding:                          16px;

// Import the template source code
@import "beagle";

// Your custom code goes here
.dropdown-item.active {
  color: $dropdown-link-active-color !important;
}
.dropdown-item:hover {
  color: $dropdown-link-color !important;
}