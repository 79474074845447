@import '../lib/beagle-sass/config/beagle-variables';

.rwc-DC-reportWarn {
  color: red;
}

.rwc-DC-notInTeam {
  font-style: italic;
}

.rwc-DC-reportSummary {
  font-size: 140%;
}

.rwc-DC-smallCalDay {
  color: #AAAAAA;
}

.rwc-DC-smallCalCount {
  font-size: 140%;
}
