@import '../lib/beagle-sass/config/beagle-variables';

.rwc-Visit-warnIcon {
  margin: 4px;
  font-size: 130%;
}

.rwc-Visit-content :first-child {
  margin-top: 0 !important;
}

.rwc-Visit-date-planned {
  font-style: italic !important;
}
.rwc-Visit-date-reported {
  font-style: normal !important;
}
.rwc-Visit-date-missed {
  text-decoration: line-through !important;
}

.rwc-Visit-dateUrgent {
  color: #FF0000;
  font-weight: bold;
}

.rwc-Visit-dateHigh {
  color: #CC6600;
}

.rwc-Visit-dateNormal {
  color: #339933;
}

.rwc-PST-wrapper {
  position: relative;
  // 60px bottom pagination section height
  height: calc(100vh - #{$top-header-height} - 60px);
  @include phone {
    // 280px top and bottom section, guessed by trying different values
    height: calc(100vh - 280px);
  }
}

.rwc-PST-footer .pagination {
  margin: 0;
}

.rwc-PST-table {
  background-color: #fff;
}

.rwc-PST-table .rwc-PST-daysRow th, .rwc-PST-table .rwc-PST-daysRow td, .rwc-PST-table .rwc-PST-monthsRow th {
  position: sticky;
  top: 0;
}

.rwc-PST-table th {
  background-color: #f5f5f5;
  padding: 4px 2px !important;
}

.rwc-PST-table td {
  padding: 4px 2px !important;
}

.rwc-PST-sumRow td {
  background-color: #fff;
}

.rwc-PST-nameCol {
  //min-width: 13em;
}

.rwc-PST-name {
  padding: 8px !important;
  background-color: #fff;
  position: sticky;
  left: 0;
}

.rwc-PST-table tr:nth-of-type(#{$table-striped-order}) .rwc-PST-name {
  background-color: $table-accent-bg;
}

.rwc-PST-visitable {
  max-width: 12em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: sticky;
}

th.rwc-PST-weekend {
  color: #fe9898;
}

td.rwc-PST-weekend {
  background-color: #ffefef;
}

.rwc-Visit-productPotential {
  width: 5em !important;
}