.rwc-AdminDiagTable {
  th, td {
    padding: 1px 4px 1px 4px;
  }
}

.rwc-AdminLocDiag-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

.rwc-AdminLocDiag-scroll {
  position: sticky;
  top: 1rem;
}

.rwc-AdminLocDiag-map {
  height: 600px;
}

.rwc-AdminLocDiag-fixed {
  position: fixed;
  top: 0;
}

.rwc-AdminLocDiag-lrPoint {
  cursor: pointer;
}

.rwc-AdminLocDiag-onMap {
  color: #e62f2f;
}
