@import '../lib/beagle-sass/config/beagle-variables';

.rwc-Cal-header {
  background-color: $body-bg;
  padding: 16px 16px 4px 16px;
}

//.rwc-Cal-ownerWrapper {
//  display: inline-block;
//  vertical-align: middle;
//  max-width: 12em;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  white-space: nowrap;
//}

.rwc-Cal-dateInput {
  width: 10em !important;
}

.rwc-Cal-ownerSelect {
  max-width: 13.5em;
}

.rwc-Cal-main {
  margin: 0;
}

.rwc-Cal-col {
  min-width: 0; // domyślnie flexbox item rozszerza się do rozmiaru elementu umieszczonego w itemie
  padding: 0 !important;
  //background-color: $left-sidebar-bg;
  background-color: $body-bg;
  border-top: 1px solid $top-header-border-color;
  border-right: 1px solid $top-header-border-color;
  border-bottom: 1px solid $top-header-border-color;
}

.rwc-Cal-monthDay {
  max-width: 20em;
}

.rwc-Cal-table {
  width: 100%;
  table-layout: auto;
  th {
    vertical-align: top;
    padding: 4px 4px 4px 12px;
  }
  td {
    min-width: 12em;
    white-space: normal !important;
    vertical-align: top;
    padding: 0 !important;
  }
  th {
    border-top: 1px solid $top-header-border-color;
    border-bottom: 1px solid $top-header-border-color;
    border-right: 1px solid $top-header-border-color;
  }
  td {
    border-bottom: 1px solid $top-header-border-color;
    border-right: 1px solid $top-header-border-color;
  }
}

.rwc-Cal-dayHeader {
  margin: 1px 1px 4px 8px;
  padding: 4px;
  font-size: 130%;
  a {
    color: $body-color;
  }
}

.rwc-Cal-dayHeader--today {
  a {
    font-weight: bold;
  }
}

.rwc-Cal-dayName {
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 1px;
}

.rwc-Cal-pictures {
  margin-bottom: 16px;
  a {
    vertical-align: top;
    display: inline-block;
    margin-right: 8px;
  }
}

.rwc-Cal-summaryCol {
  white-space: nowrap;
  margin-bottom: 8px;
}

.rwc-Cal-eventsNew {
  min-width: 0;
}

.rwc-Cal-event {
  margin: 8px;
  padding: 8px 4px;
  display: block;
  color: $body-color;
  background-color: $white;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px 1px 1px 5px;
  //border-top-color: $btn-secondary-border;
  //border-right-color: $btn-secondary-border;
  //border-bottom-color: $btn-secondary-border;
  border-top-color: $white;
  border-right-color: $white;
  border-bottom-color: $white;
  border-left-color: #AAAAAA;
  &:hover {
    color: $body-color;
    border-top: 1px solid darken($btn-secondary-border, 13%);
    border-right: 1px solid darken($btn-secondary-border, 13%);
    border-bottom: 1px solid darken($btn-secondary-border, 13%);
  }
}

.rwc-Cal-event-title {
  .badge {
    float: right;
    margin-left: 2px;
    color: theme-color('secondary');
  }
}

.rwc-Cal-event-details {
  p {
    margin-bottom: 0;
  }
}

.rwc-Cal-oneLine {
  white-space: nowrap;
  overflow: hidden;
  //text-overflow: ellipsis;
}

.rwc-Cal-eventSummary {
  border-left-color: #636262;
}

.rwc-Cal-eventActivityMissing {
  display: block;
  border-left-color: red;
}

.rwc-Cal-eventVisit {
  &.rwc-Visit-health-planned {
    border-left-color: #00bbff;
  }

  &.rwc-Visit-health-reported {
    border-left-color: #007799;
  }

  &.rwc-Visit-health-missed {
    border-left-color: #00303d;

    .rwc-Cal-event-title {
      text-decoration: line-through;
    }
  }

  &.rwc-Visit-pharmacy-planned {
    border-left-color: #C1E8BE;
  }

  &.rwc-Visit-pharmacy-reported {
    border-left-color: #56AD4F
  }

  &.rwc-Visit-pharmacy-missed {
    border-left-color: #162b14;

    .rwc-Cal-event-title {
      text-decoration: line-through;
    }
  }
}

.rwc-Visit-no-geolocation {
  position: relative;
}
.rwc-Visit-no-geolocation:after {
  // alert-triangle
  content: "\f1f4";
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 130%;
  position: absolute;
  right: 4px;
  top: 0;
  color: #db0000;
}

.rwc-Visit-draft-warning {
  position: relative;
}
.rwc-Visit-draft-warning:after {
  // alert-triangle
  content: "\f1f4";
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 130%;
  position: absolute;
  right: 4px;
  top: 0;
  color: #db0000;
}

.rwc-Visit-required-approval {
  position: relative;
}
.rwc-Visit-required-approval:after {
  // edit
  content: "\f25d";
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 130%;
  position: absolute;
  right: 4px;
  top: 0;
  color: #d4c700;
}

.rwc-Visit-target-warning {
  position: relative;
}
.rwc-Visit-target-warning:after {
  // flag
  content: "\f162";
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 130%;
  position: absolute;
  right: 4px;
  top: 0;
  color: red;
}

