@import '../lib/beagle-sass/config/beagle-variables';

.rwc-ISF-mainActions {
  padding: 0;
  margin: 16px 22px 0;
  width: auto;
  height: auto;
  top: 0;
  right: 0;
  position: absolute;

  @include tablet {
    margin-right: 40px;
  }
}

.rwc-ISF-areasTree {
  width: auto;
  height: 200px;
}

.rwc-ISF-areasBtn {
  padding: 8px 16px !important;
}

// make elements invisible to eliminate unpleasant flickering
#institution_search_city_array,
#institution_search_street_array,
#institution_search_tags_array,
#institution_search_category_array,
#institution_search_target_array {
  height: 44.5px;
  color: #fff;
  border-color: #fff;
  overflow: hidden;
}

.rwc-ISM-mapCanvas {
  width: 100%;
  height: calc(100vh - #{$top-header-height} - 1px);
  @include phone {
    // TODO remove constant
    height: calc(100vh - 175px);
  }
}

.rwc-ISM-infoWin {
  overflow-y: auto;
  min-height: 100px;
  max-height: 350px;
  min-width: 200px;
}

.rwc-Inst-mapWrapper {
}

.rwc-Inst-mapWrapper--visible {
  margin: 0;
  padding: 1px;
  height: 360px;
}

.rwc-LocFix-mapWrapper {
  height: 420px;
}