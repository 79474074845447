/*------------------------------------------------------------------
  [Main content]
*/
.be-content{
	position: relative;
	left: 0;
	margin-left: $left-sidebar-width;
	min-height: 100%;

	@include tablet {
		margin-left: 0;
	}

	@media #{$print} {
		margin-left: 0;
	}

	.be-nosidebar-left & {
		margin-left: 0;
	}

	.be-collapsible-sidebar-collapsed & {
		margin-left: $left-sidebar-collapsed-width;

		@include tablet {
			margin-left: 0;
		}
	}

	.be-offcanvas-menu & {
		margin-left: 0;
	}

	// Off canvas menu only on mobile
	@include non-responsive {

		.be-offcanvas-menu-sm & {
			margin-left: $left-sidebar-width;
		}
	}

	.be-mega-menu & {
		margin-left: auto;
	}

	@include non-responsive{
		.be-boxed-layout & {
			margin-left: 0;
			overflow: hidden;
			width: 100%;
		}

		.be-boxed-layout.be-fixed-sidebar & {
			margin-left: $left-sidebar-width;
			min-height: 100vh;
		}
	}
}

.main-content {
	padding: $grid-gutter-width - 5;

	@include laptop {
		padding-left: $grid-gutter-width - 5;
		padding-right: $grid-gutter-width - 5;
		padding-top: $grid-gutter-width;
	}

	@include tablet {
		height: 100%;
		overflow: auto;
		padding-left: $grid-gutter-width - 15;
		padding-right: $grid-gutter-width - 15;
		padding-top: 20px;
	}

	@include phone {
		padding-right: $grid-gutter-width - 20;
		padding-left: $grid-gutter-width - 20;

		//.row {
		//	margin-left: -($grid-gutter-width - 20);
		//	margin-right: -($grid-gutter-width - 20);
		//}
		//
		//%grid-column {
		//	padding-left: $grid-gutter-width - 20;
		//	padding-right: $grid-gutter-width - 20;
		//}
	}

	.be-no-padding &{
		padding: 0;
	}
}
