@import '../lib/beagle-sass/config/beagle-variables';

.rwc-PSF-mainActions {
  padding: 0;
  margin: 16px 22px 0;
  width: auto;
  height: auto;
  top: 0;
  right: 0;
  position: absolute;

  @include tablet {
    margin-right: 40px;
  }
}

.rwc-PSF-areasTree {
  width: auto;
  height: 200px;
}

.rwc-PSF-areasBtn, .rwc-PSF-advancedBtn, .rwc-PSF-potentialsBtn {
  padding: 8px 16px !important;
}

// make elements invisible to eliminate unpleasant flickering
#person_search_specialization_array,
#person_search_city_array,
#person_search_street_array,
#person_search_tags_array,
#person_search_target_array {
  height: 44.5px;
  color: #fff;
  border-color: #fff;
  overflow: hidden;
}

.rwc-PSM-mapCanvas {
  width: 100%;
  height: calc(100vh - #{$top-header-height} - 1px);
  @include phone {
    // TODO remove constant
    height: calc(100vh - 175px);
  }
}

.rwc-PSM-infoWin {
  overflow-y: auto;
  min-height: 100px;
  max-height: 350px;
  min-width: 200px;
}